import React, { useEffect, useState } from "react";
import { Row, Col } from 'reactstrap';
import Layout from "../../components/Layout";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import houseImg from "../../assets/images/dvuet.svg";
import { getFaqInfo } from '../../reducers/About/actions'
import { connect } from 'react-redux'
import SideNav from '../../components/SideNav/SideNav'
import { urlDescription } from '../../utils/pageDescription'
import "./styles.scss";

const FAQ = ({
  data,
  getFaqInfo,
}) => {
  const [expanded, setExpanded] = useState('');
  const [toggle, setToggle] = useState(false);
  const [currentTab, setCurrentTab] = useState("BuildingCompanion");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setToggle(false)
  };

  useEffect(() => {
    getFaqInfo({
      type: currentTab,
      page: data && data.current_page || 1
    })
  }, [getFaqInfo, currentTab])

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="faq-title-wrapper mt-5">
        <h1 className="font-weight-bold">Често задавани въпроси</h1>
      </div>
      <div className="faq-wrapper">
        <div className="faq-container">
          <Row>
            <Col className="mt-5 mb-3 faq-title" lg={12}>
              <h2
                className="h3 font-weight-bold">
                {
                  currentTab === "BuildingCompanion"
                    ? "За Building Companion"
                    : "Продукти и решения на КСЕЛА"
                }
              </h2>
            </Col>
            <Col className='mb-5 sidenav-menu-col' xs={12} md={4} lg={4}>
              <SideNav
                faq
                currentTab={currentTab}
                setToggle={setToggle}
                setCurrentTab={setCurrentTab}
              />
            </Col>
            {/* <Col className="news-menu-col" lg={4} md={4}>
              <div className="news-menu">
                <div className="news-menu-list">
                  <div
                    className="d-grid"
                  >
                    <hr />
                    <Link
                      className="px-2 no-underline-link"
                    >
                      <span onClick={() => {
                        setCurrentTab('BuildingCompanion');
                        setToggle(true);
                      }}>
                        За Building Companion
                      </span>
                    </Link>
                    <hr />
                    <Link className="px-2 no-underline-link">
                      <span onClick={() => {
                        setCurrentTab('XellaProducts');
                        setToggle(true);
                      }}>
                        Продукти и решения на КСЕЛА
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </Col> */}
            <Col lg={8}>
              {data && data.items
                && data.items.map((item, i) => (
                  <Accordion
                    onChange={handleChange(`panel${i}`)}
                    defaultExpanded={false}
                    expanded={toggle ? false : expanded === `panel${i}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${i}bh-content`}
                      id={`panel${i}bh-header`}
                    >
                      <Typography sx={{ width: '10%', flexShrink: 0 }}>
                        <div className="faq-icon-container">
                          <img
                            alt="icon"
                            className="icon filter"
                            src={houseImg}
                          />
                        </div>
                      </Typography>
                      <Typography sx={{ color: 'text.secondary', alignSelf: 'center' }}>
                        {item.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            </Col>
          </Row>
        </div>
      </div>
    </Layout >
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.aboutInfo.faq
  };
};

const mapDispatchToProps = {
  getFaqInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
